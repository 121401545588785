<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row justify="center">
        <v-col cols="10">
          <v-btn
            block
            tile
            class="elevation-0 white--text"
            :color="isCycleComplete ? 'success' : 'grey darken-4'"
            @click="dialog = true"
            v-bind="attrs"
            v-on="on"
          >
            <span>{{
              isCycleComplete ? "Start next cycle" : "Start cycle over"
            }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-card tile class="d-block">
      <v-toolbar dark>
        <v-btn icon dark @click="dialog = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          isCycleComplete ? "Start next cycle" : "Start cycle over"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="px-0 py-0 text-body-1">
        <v-form>
          <v-stepper flat tile v-model="e1" vertical>
            <v-stepper-step :complete="e1 > 1" step="1"
              >1 Rep Maxes</v-stepper-step
            >

            <v-stepper-content step="1">
              <v-container>
                <v-row>
                  <p>Enter new starting weight values for each lift.</p>
                  <p><strong>Recommended:</strong></p>
                  <ul>
                    <li>
                      Upper body lifts <strong>+5lbs</strong> from previous best
                      (military and bench)
                    </li>
                    <li>
                      Lower body lifts <strong>+10lbs</strong> from previous
                      best (squat and deadlift)
                    </li>
                  </ul>
                </v-row>
                <v-row class="mt-6">
                  <v-col cols="12">
                    <v-text-field
                      solo
                      required
                      :error-messages="benchErrors"
                      v-model="bench"
                      type="number"
                      label="Bench"
                      suffix="lbs"
                      @input="$v.bench.$touch()"
                      @blur="$v.bench.$touch()"
                    ></v-text-field>
                    <span
                      >Previous best:
                      <strong>{{ previousBest.bench }} lbs</strong></span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      solo
                      required
                      :error-messages="militaryErrors"
                      v-model="military"
                      type="number"
                      label="Military press"
                      suffix="lbs"
                      @input="$v.military.$touch()"
                      @blur="$v.military.$touch()"
                    ></v-text-field>
                    <span
                      >Previous best:
                      <strong>{{ previousBest.military }} lbs</strong></span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      solo
                      required
                      v-model="squat"
                      type="number"
                      :error-messages="squatErrors"
                      label="Squat"
                      suffix="lbs"
                      @input="$v.squat.$touch()"
                      @blur="$v.squat.$touch()"
                    ></v-text-field>
                    <span
                      >Previous best:
                      <strong>{{ previousBest.squat }} lbs</strong></span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      solo
                      required
                      :error-messages="deadliftErrors"
                      v-model="deadlift"
                      type="number"
                      label="Deadlift"
                      suffix="lbs"
                      @input="$v.deadlift.$touch()"
                      @blur="$v.deadlift.$touch()"
                    ></v-text-field>
                    <span
                      >Previous best:
                      <strong>{{ previousBest.deadlift }} lbs</strong></span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-btn text color="warning" @click="submitStep1">
                      Continue<v-icon>{{ mdiChevronRight }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-step :complete="e1 > 2" step="2"
              >Pick template</v-stepper-step
            >

            <v-stepper-content step="2">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      dense
                      required
                      v-model="template"
                      :error-messages="templateErrors"
                      :items="templates"
                      label="Template"
                      filled
                      @change="$v.template.$touch()"
                      @blur="$v.template.$touch()"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-btn text @click="e1 -= 1">
                      <v-icon>{{ mdiChevronLeft }}</v-icon> go back
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn text color="warning" @click="submitStep2">
                      Continue<v-icon>{{ mdiChevronRight }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-step step="3">Pick schedule</v-stepper-step>

            <v-stepper-content step="3">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      dense
                      required
                      filled
                      v-model="schedule"
                      :error-messages="scheduleErrors"
                      :items="schedules"
                      label="Schedule"
                      @change="$v.schedule.$touch()"
                      @blur="$v.schedule.$touch()"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-btn text @click="e1 -= 1">
                      <v-icon>{{ mdiChevronLeft }}</v-icon
                      >go back
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      text
                      color="warning"
                      :disabled="$v.$invalid"
                      @click="isCycleComplete ? startNextCycle() : startCycle()"
                    >
                      Let's start<v-icon>{{ mdiChevronRight }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
          </v-stepper>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiChevronRight, mdiChevronLeft, mdiClose } from "@mdi/js";

import { validationMixin } from "vuelidate";
import { required, between } from "vuelidate/lib/validators";

export default {
  name: "NextCycleDialog",
  mixins: [validationMixin],
  validations: {
    bench: { required, between: between(1, 1080) },
    squat: { required, between: between(1, 1105) },
    deadlift: { required, between: between(1, 1104) },
    military: { required, between: between(1, 500) },
    schedule: { required },
    template: { required },
  },
  data() {
    return {
      mdiClose,
      mdiChevronRight,
      mdiChevronLeft,
      dialog: false,
      schedule: "",
      template: "",
      bench: "",
      squat: "",
      deadlift: "",
      military: "",
      templates: [
        {
          text: "3 Days a week",
          value: "three_day_split",
        },
      ],
      schedules: [
        {
          text: "Mon - Wed - Fri",
          value: "monday",
        },
        {
          text: "Tue - Thu - Sat",
          value: "tuesday",
        },
      ],
      e1: 1,
    };
  },
  computed: {
    isCycleComplete() {
      return this.$store.state.userProfile.cycle.weeks.week5.isActive;
    },
    previousBest() {
      return this.$store.state.userProfile.maxes;
    },
    templateErrors() {
      const errors = [];
      if (!this.$v.template.$dirty) return errors;
      !this.$v.template.required && errors.push("Item is required");
      return errors;
    },
    scheduleErrors() {
      const errors = [];
      if (!this.$v.schedule.$dirty) return errors;
      !this.$v.schedule.required && errors.push("Item is required");
      return errors;
    },
    benchErrors() {
      const errors = [];
      if (!this.$v.bench.$dirty) return errors;
      !this.$v.bench.between &&
        errors.push("Can't be more than 1080lbs. Current WR.");
      !this.$v.bench.required && errors.push("Item is required");
      return errors;
    },
    squatErrors() {
      const errors = [];
      if (!this.$v.squat.$dirty) return errors;
      !this.$v.squat.between &&
        errors.push("Can't be more than 1105lbs. Current WR.");
      !this.$v.squat.required && errors.push("Item is required");
      return errors;
    },
    deadliftErrors() {
      const errors = [];
      if (!this.$v.deadlift.$dirty) return errors;
      !this.$v.deadlift.between &&
        errors.push("Can't be more than 1104lbs. Current WR.");
      !this.$v.deadlift.required && errors.push("Item is required");
      return errors;
    },
    militaryErrors() {
      const errors = [];
      if (!this.$v.military.$dirty) return errors;
      !this.$v.military.between &&
        errors.push("Can't be more than 500lbs. Current WR.");
      !this.$v.military.required && errors.push("Item is required");
      return errors;
    },
  },
  methods: {
    submitStep1() {
      this.$v.bench.$touch();
      this.$v.squat.$touch();
      this.$v.deadlift.$touch();
      this.$v.military.$touch();
      if (
        !this.$v.bench.$invalid &&
        !this.$v.squat.$invalid &&
        !this.$v.deadlift.$invalid &&
        !this.$v.military.$invalid
      )
        this.e1 += 1;
    },
    submitStep2() {
      this.$v.template.$touch();
      if (!this.$v.template.$invalid) this.e1 += 1;
    },
    async startCycle() {
      const data = {
        maxes: {
          bench: this.bench,
          squat: this.squat,
          deadlift: this.deadlift,
          military: this.military,
        },
        schedule: this.schedule,
        template: this.template,
      };

      await this.$store.dispatch("updateUserProfile", data);
      await this.$store.dispatch("generateUserCycle");
      this.dialog = false;
    },
    startNextCycle() {
      const key =
        "cycle" +
        (Object.keys(this.$store.state.userProfile.old_cycles).length + 1);
      const updates = {
        [key]: {
          ...this.$store.state.userProfile.cycle.reps,
          completedOn: Date.now(),
          isCompleted: true,
          startedOn: this.$store.state.userProfile.cycle.startedOn,
        },
      };
      this.$store.dispatch("saveCycle", updates);
      this.$store.dispatch("markCurrentCycleCompleted", {
        isCompleted: true,
        completedOn: Date.now(),
      });
      this.startCycle();
    },
  },
};
</script>

<style lang="scss" scoped></style>
