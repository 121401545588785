<template>
  <v-container>
    <v-row justify="end" class="mb-2 pr-4 mt-5" align="center">
      <span class="pr-4">Hi, {{ userProfile.displayName }}</span>
      <v-avatar size="50">
        <img :src="userProfile.profilePicture" alt="" />
      </v-avatar>
    </v-row>
    <v-row class="mt-8">
      <v-btn @click="learnMore" text block tile>
        <v-row class="pl-8">
          <v-icon left>{{ mdiInformation }}</v-icon>
          <span class="font-weight-light">LEARN MORE</span>
        </v-row>
      </v-btn>
    </v-row>
    <v-row class="mt-5">
      <v-dialog v-model="confirmLogout" max-width="350">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="warning" block text tile>
            <v-row class="pl-8">
              <v-icon left color="warning">{{ mdiLogout }}</v-icon>
              <span class="font-weight-light">SIGN OUT</span>
            </v-row>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Log out from 5/3/1?</v-card-title>
          <v-card-text>You will be returned to the login screen.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer
            ><v-btn text tile color="warning" @click="logOut"
              >Yes, log out</v-btn
            ><v-btn text tile @click="confirmLogout = false"
              >Cancel</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mdiInformation, mdiLogout } from "@mdi/js";
import { mapState } from "vuex";

export default {
  name: "TheNavDrawer",
  data() {
    return {
      mdiInformation,
      mdiLogout,
      confirmLogout: false,
    };
  },
  computed: {
    ...mapState(["userProfile", "navDrawer"]),
  },
  methods: {
    learnMore() {
      window.open(
        "https://www.t-nation.com/training/5-3-1-how-to-build-pure-strength/",
        "_blank"
      );
    },
    logOut() {
      this.$emit("toggle:drawer");
      this.$store.dispatch("logOut");
    },
  },
};
</script>

<style lang="scss" scoped></style>
