<template>
  <v-row justify="center">
    <v-col class="pb-0" cols="10">
      <v-card
        :to="link"
        :color="active ? 'warning' : completed ? 'success' : ''"
        tile
        raised
        :elevation="completed ? '0' : ''"
        :disabled="!active && !completed"
      >
        <v-card-title
          class="pl-5 align-baseline"
          :class="{
            'text--inactive': !completed && !active,
            'text--default': completed || active,
          }"
          ><span class="font-weight-bold"><slot name="title">Bench</slot></span>
          <span class="pl-1 font-weight-light text-subtitle-1"
            ><slot name="title2"></slot
          ></span>
        </v-card-title>
        <v-card-subtitle
          class="pl-5"
          :class="{
            'text--theme-based': !completed && !active,
            'text--default': completed || active,
          }"
        >
          <span class="font-weight-light"
            ><slot name="subtitle">Last set @205 x 5+</slot></span
          >
        </v-card-subtitle>

        <v-icon
          color="grey darken-4"
          style="position: absolute; right: 16px; top: calc(50% - 12px);"
          >{{ completed ? mdiCheck : active ? mdiChevronRight : "" }}</v-icon
        >
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiChevronRight, mdiCheck } from "@mdi/js";

export default {
  name: "HorizontalCard",
  props: ["active", "link", "completed"],
  data() {
    return {
      mdiChevronRight,
      mdiCheck,
    };
  },
};
</script>

<style lang="scss" scoped>
.text--inactive {
  color: white !important;
}
.text--default {
  color: #212121 !important;
}
@media (prefers-color-scheme: light) {
  .text--inactive {
    color: black !important;
  }
  .text--default {
    color: #212121 !important;
  }
}
</style>
