import { db } from "../firebase";

export const repsRef = uid => db.ref("users/" + uid + "/cycle/reps/");
export const oldCyclesRef = uid => db.ref("users/" + uid + "/old_cycles/");
export const userRef = uid => db.ref("users/" + uid + "/");
export const userCycleRef = uid => db.ref("users/" + uid + "/cycle/");
export const userCycleWeeksRef = uid =>
  db.ref("users/" + uid + "/cycle/weeks/");
export const userTemplateRef = uid => db.ref("users/" + uid + "/template");
export const progressRef = uid => db.ref("users/" + uid + "/cycle/progress/");

export const templateRef = template => db.ref("templates/" + template);
