































import * as firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["loadingUserData"]),
  },
  methods: {
    learnMore() {
      window.open(
        "https://www.t-nation.com/training/5-3-1-how-to-build-pure-strength/",
        "_blank"
      );
    },
  },
  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    const uiconfig = {
      signInSuccessUrl: "/",
      signInFlow: "popup",
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        },
      ],
      privacyPolicyUrl: "https://531.elysaakian.com/privacy",
    };
    ui.start("#firebaseui-auth-container", uiconfig);
  },
};
