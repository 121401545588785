<template>
  <v-row align="center" justify="space-between">
    <v-col cols="6">
      <v-row justify="start" :class="isChecked ? 'disabled' : ''">
        <v-col cols="auto">
          <span class="text-h4 font-weight-bold">{{ weight }}</span
          ><span class="font-weight-light align-self-end">lbs</span
          ><span class="text-h4 font-weight-bold">{{ reps }}</span
          ><span class="font-weight-light align-self-end">reps</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row align="center" justify="end">
        <v-col cols="auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="27"
            :class="isChecked ? 'disabled' : ''"
            @click="completed ? null : $emit('toggle-weight-helper', weight)"
          >
            <g
              id="Group_222"
              data-name="Group 222"
              transform="translate(-226 -264)"
            >
              <rect
                fill="#272727"
                id="Rectangle_1485"
                data-name="Rectangle 1485"
                width="45"
                height="27"
                rx="5"
                transform="translate(226 264)"
              />
              <g
                id="icon_content_filter_list_24px"
                data-name="icon/content/filter_list_24px"
                transform="translate(246.597 295.403) rotate(-90)"
              >
                <path
                  id="Path"
                  d="M0,2H32V0H0Z"
                  transform="translate(18.403 -13.597) rotate(90)"
                  fill="#fff"
                />
                <path
                  id="Path-2"
                  data-name="Path"
                  d="M0,4H14V0H0Z"
                  transform="translate(10.403 -12.597)"
                  fill="#fff"
                />
                <path
                  id="Path-3"
                  data-name="Path"
                  d="M0,4H16V0H0Z"
                  transform="translate(9.403 -7.597)"
                  fill="#fff"
                />
                <path
                  id="Path-4"
                  data-name="Path"
                  d="M0,4H14V0H0Z"
                  transform="translate(10.403 13.403)"
                  fill="#fff"
                />
                <path
                  id="Path-5"
                  data-name="Path"
                  d="M0,4H16V0H0Z"
                  transform="translate(9.403 8.403)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg> </v-col
        ><v-col cols="auto">
          <v-simple-checkbox
            @click="$emit('set-state-change', isChecked)"
            class="ml-5 scale align-self-start"
            v-model="isChecked"
            :ripple="false"
          ></v-simple-checkbox>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DayCardRow",
  components: {},
  props: ["weight", "reps", "completed"],
  data() {
    return {
      isChecked: this.completed,
    };
  },
};
</script>

<style lang="scss" scoped>
.scale {
  transform: scale(1.5);
}
svg:hover {
  cursor: pointer;
}

.disabled {
  cursor: default;
  opacity: 0.5;
}
</style>
