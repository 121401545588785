<template>
  <div id="app">
    <router-view name="app"></router-view>
    <router-view name="signIn"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
.slide-left-leave-active,
.slide-left-enter-active {
  transition: 0.3s ease-in-out;
}
.slide-left-enter {
  transform: translate(0, 100vh);
}
.slide-left-leave-to {
  transform: translate(0, -100vh);
}
.slide-right-leave-active,
.slide-right-enter-active {
  transition: 0.3s ease-in-out;
}
.slide-right-enter {
  transform: translate(0, -100vh);
}
.slide-right-leave-to {
  transform: translate(0, 100vh);
}

.fade-leave-active,
.fade-enter-active {
  transition: 0.1s ease-in-out;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  opacity: 1;
}
.v-dialog {
  border-radius: 0px !important;
}
</style>
