<template>
  <v-bottom-navigation
    app
    class="elevation-0"
    :value="computedNavIndex"
    shift
    mandatory
  >
    <v-tabs
      grow
      height="56"
      :value="computedNavIndex"
      slider-size="4"
      icons-and-text
    >
      <v-tab class="px-0 myBtn"
        ><v-btn
          :ripple="false"
          to="/account"
          block
          height="100%"
          class="myBtn mb-0"
        >
          <span>ACCOUNT</span>

          <v-icon>{{ mdiAccount }}</v-icon>
        </v-btn></v-tab
      >
      <v-tab class="px-0 myBtn">
        <v-btn
          :ripple="false"
          to="/cycle"
          block
          height="100%"
          class="myBtn mb-0"
        >
          <span>CYCLE</span>

          <v-icon>{{ mdiViewList }}</v-icon>
        </v-btn>
      </v-tab>
      <v-tab class="px-0 myBtn">
        <v-btn
          :ripple="false"
          to="/stats"
          block
          height="100%"
          class="myBtn mb-0"
        >
          <span>STATS</span>

          <v-icon>{{ mdiPoll }}</v-icon>
        </v-btn>
      </v-tab>
    </v-tabs>
  </v-bottom-navigation>
</template>

<script>
import { mdiAccount, mdiViewList, mdiPoll } from "@mdi/js";

export default {
  name: "TheBottomNav",
  data() {
    return {
      mdiAccount,
      mdiViewList,
      mdiPoll,
    };
  },
  computed: {
    computedNavIndex() {
      const path = this.$route.name;
      const indexes = {
        Account: 0,
        Cycle: 1,
        WeekView: 1,
        DayView: 1,
        Stats: 2,
        NewUser: 1,
      };
      return indexes[path];
    },
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;

      const transitionName =
        toDepth == fromDepth
          ? "fade"
          : toDepth < fromDepth
          ? "slide-right"
          : "slide-left";

      this.$emit("change:transitionName", transitionName);
    },
  },
};
</script>

<style lang="scss" scoped>
.myBtn::before {
  opacity: 0 !important;
}
</style>
