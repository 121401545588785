<template>
  <v-card color="warning" tile>
    <v-card-title
      ><span class="text-h4 font-weight-bold">{{ weight }}</span
      ><span class="font-weight-light align-self-end">lbs</span></v-card-title
    >

    <v-card-subtitle class="mt-1 text-body-1"
      >Put these on each side of the barbell</v-card-subtitle
    >

    <v-card-text>
      <WeightHelperRow
        v-for="(quantity, plate) in plates"
        :key="plate"
        :plate="plate"
        :quantity="quantity"
      ></WeightHelperRow>
    </v-card-text>

    <v-card-actions class="close-btn">
      <v-btn icon @click="$emit('toggle-weight-helper')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import WeightHelperRow from "./WeightHelperRow";
import { getPlates } from "../utils/getPlates";

export default {
  name: "WeightHelper",
  components: {
    WeightHelperRow,
  },
  props: ["weight"],
  computed: {
    plates() {
      return getPlates(this.weight);
    },
  },
};
</script>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.barbell {
  position: absolute;
  top: -300px;
  left: 30px;
  opacity: 1;
  z-index: 0;
  opacity: 0.08;
  fill: white;
}
</style>
