<template>
  <v-container>
    <NextCycleDialog></NextCycleDialog>
    <HorizontalCard
      v-for="(week, key, index) in userCycleWeeks"
      :class="index == Object.keys(userCycleWeeks).length - 1 ? 'mb-1' : ''"
      :key="key"
      :active="week.isActive"
      :completed="week.isCompleted"
      :link="'week' + '/' + (index + 1)"
    >
      <template #title
        >Week {{ index + 1 }}
        {{
          index + 1 == Object.values(userCycleWeeks).length ? "Deload" : ""
        }}</template
      >
      <template #subtitle>{{
        `${toTitleCase(week[0].lift)}, ${toTitleCase(
          week[1].lift
        )}, ${toTitleCase(week[2].lift)}`
      }}</template>
    </HorizontalCard>
  </v-container>
</template>

<script>
import HorizontalCard from "../components/HorizontalCard";
import NextCycleDialog from "../components/NextCycleDialog";

export default {
  name: "CycleView",
  components: {
    HorizontalCard,
    NextCycleDialog,
  },
  methods: {
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    startNextCycle() {
      const key =
        "cycle" +
        (Object.keys(this.$store.state.userProfile.old_cycles).length + 1);
      const updates = {
        [key]: this.$store.state.userProfile.cycle.reps,
      };
      this.$store.dispatch("saveCycle", updates);
      this.$store.dispatch("markCurrentCycleCompleted", {
        isCompleted: true,
        completedOn: Date.now(),
      });
      this.$router.push("/setup");
    },
  },
  computed: {
    isCycleComplete() {
      return !(
        this.$store.state.userProfile.cycle.isCompleted &&
        this.$store.state.userProfile.cycle.weeks.week5.isActive
      );
    },
    userCycleWeeks() {
      return this.$store.state.userProfile.cycle.weeks;
    },
  },
};
</script>

<style lang="scss" scoped></style>
