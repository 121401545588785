import Vue from "vue";
import Vuetify from "vuetify/lib";

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    themes: {
      light: {
        primary: "#212121",
        success: "#0091EA",
      },
      dark: {
        success: "#0091EA",
      },
    },
    dark: window.matchMedia("(prefers-color-scheme: dark)").matches,
  },
});
Vue.use(Vuetify);
