import "./types";

import { getPlateRoundedWeight } from "../utils/getPlates";

export default function generateCycle(
  template: Template,
  userProfile: UserProfile
): Cycle {
  const phase1 = template.main["phase1"];
  const phase2 = template.main["phase2"];
  const phase3 = template.main["phase3"];
  const deload = template.main["deload"];
  const phases: phases = {
    phase1,
    phase2,
    phase3,
    deload,
  };

  type phases = {
    [phase: string]: Phase;
  };

  const { weeks } = template;
  const { assistance } = template;

  const { bench, deadlift, military, squat } = userProfile.maxes;

  const reps = {
    bench: {
      phase1: {
        weight: bench,
        reps: "",
      },
      phase2: {
        weight: bench,
        reps: "",
      },
      phase3: {
        weight: bench,
        reps: "",
      },
    },
    deadlift: {
      phase1: {
        weight: deadlift,
        reps: "",
      },
      phase2: {
        weight: deadlift,
        reps: "",
      },
      phase3: {
        weight: deadlift,
        reps: "",
      },
    },
    squat: {
      phase1: {
        weight: squat,
        reps: "",
      },
      phase2: {
        weight: squat,
        reps: "",
      },
      phase3: {
        weight: squat,
        reps: "",
      },
    },
    military: {
      phase1: {
        weight: military,
        reps: "",
      },
      phase2: {
        weight: military,
        reps: "",
      },
      phase3: {
        weight: military,
        reps: "",
      },
    },
  };

  const cycle: Cycle = {
    weeks,
    assistance,
    reps,
    isCompleted: false,
    startedOn: Date.now(),
  };
  const liftsMaxes: lifts = {
    bench,
    deadlift,
    military,
    squat,
  };

  type lifts = {
    [lift: string]: number;
  };

  function generateSets(sets, phase, day) {
    for (const set in phases[phase]) {
      const setTemplate = phases[phase][set];
      // newSet object using phases map to look up and calculate weight
      const newSet = {
        completed: false,
        reps: setTemplate.reps,
        weight: getPlateRoundedWeight(
          Math.ceil((setTemplate.weight * 0.9 * liftsMaxes[day.lift]) / 5) * 5,
          day.lift == "deadlift" || day.lift == "squat"
        ),
      };
      // pushing newSet to sets array
      sets.push(newSet);
    }
  }

  // going through each week
  for (const week in cycle.weeks) {
    const templateWeek: Week = template.weeks[week];
    const weekDays: Day[] = [templateWeek[0], templateWeek[1], templateWeek[2]];

    // going through each day in a week
    for (let i = 0; i < weekDays.length; i++) {
      const day = weekDays[i];
      // empty array of sets per lift
      const sets: WorkingSet[] = [];
      // empty array of warmup sets per lift
      const warmupSets: WorkingSet[] = [];
      // getting name of the phase from the lift this week
      //@ts-ignore
      const phase = day.phase;
      // going through each set in the phase
      generateSets(sets, phase, day);
      generateSets(warmupSets, "deload", day);
      // assignning generated set to the day of the week
      cycle.weeks[week][i] = {
        warmupSets,
        sets,
        lift: day.lift,
        day: day.day,
        phase,
        isCompleted: false,
        isActive: week == "week1" && i == 0 ? true : false,
      };
    }
  }

  return cycle;
}
