<template>
  <v-app>
    <v-dialog
      v-model="dialog"
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile class="d-block">
        <v-toolbar dark>
          <v-toolbar-title>New cycle</v-toolbar-title>
        </v-toolbar>
        <v-card-title
          >Hi, {{ $store.state.userProfile.displayName }}!
        </v-card-title>
        <v-card-subtitle
          >Let's get your first cycle of 5/3/1 set up.</v-card-subtitle
        >
        <v-card-text class="px-0 py-0 text-body-1">
          <v-form>
            <v-stepper tile v-model="e1" vertical>
              <v-stepper-step :complete="e1 > 1" step="1"
                >1 Rep Maxes</v-stepper-step
              >

              <v-stepper-content step="1">
                <v-container>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        solo
                        required
                        :error-messages="benchErrors"
                        v-model="bench"
                        type="number"
                        label="Bench"
                        suffix="lbs"
                        @input="$v.bench.$touch()"
                        @blur="$v.bench.$touch()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        solo
                        required
                        :error-messages="militaryErrors"
                        v-model="military"
                        type="number"
                        label="Military press"
                        suffix="lbs"
                        @input="$v.military.$touch()"
                        @blur="$v.military.$touch()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        solo
                        required
                        v-model="squat"
                        type="number"
                        :error-messages="squatErrors"
                        label="Squat"
                        suffix="lbs"
                        @input="$v.squat.$touch()"
                        @blur="$v.squat.$touch()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        solo
                        required
                        :error-messages="deadliftErrors"
                        v-model="deadlift"
                        type="number"
                        label="Deadlift"
                        suffix="lbs"
                        @input="$v.deadlift.$touch()"
                        @blur="$v.deadlift.$touch()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-btn text color="warning" @click="submitStep1">
                        Continue<v-icon>{{ mdiChevronRight }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>

              <v-stepper-step :complete="e1 > 2" step="2"
                >Pick template</v-stepper-step
              >

              <v-stepper-content step="2">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        dense
                        required
                        v-model="template"
                        :error-messages="templateErrors"
                        :items="templates"
                        label="Template"
                        filled
                        @change="$v.template.$touch()"
                        @blur="$v.template.$touch()"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-btn text @click="e1 -= 1">
                        <v-icon>{{ mdiChevronLeft }}</v-icon> go back
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn text color="warning" @click="submitStep2">
                        Continue<v-icon>{{ mdiChevronRight }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>

              <v-stepper-step step="3">Pick schedule</v-stepper-step>

              <v-stepper-content step="3">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        dense
                        required
                        filled
                        v-model="schedule"
                        :error-messages="scheduleErrors"
                        :items="schedules"
                        label="Schedule"
                        @change="$v.schedule.$touch()"
                        @blur="$v.schedule.$touch()"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-btn text @click="e1 -= 1">
                        <v-icon>{{ mdiChevronLeft }}</v-icon
                        >go back
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        text
                        color="warning"
                        :disabled="$v.$invalid"
                        @click="submitNewUserInfo"
                      >
                        Let's start<v-icon>{{ mdiChevronRight }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mdiChevronRight, mdiChevronLeft, mdiClose } from "@mdi/js";

import { validationMixin } from "vuelidate";
import { required, between } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    bench: { required, between: between(1, 1080) },
    squat: { required, between: between(1, 1105) },
    deadlift: { required, between: between(1, 1104) },
    military: { required, between: between(1, 500) },
    schedule: { required },
    template: { required },
  },
  name: "NewUser",
  data() {
    return {
      mdiChevronRight,
      mdiChevronLeft,
      mdiClose,
      dialog: true,
      schedule: "",
      template: "",
      bench: "",
      squat: "",
      deadlift: "",
      military: "",
      templates: [
        {
          text: "3 Days a week",
          value: "three_day_split",
        },
      ],
      schedules: [
        {
          text: "Mon - Wed - Fri",
          value: "monday",
        },
        {
          text: "Tue - Thu - Sat",
          value: "tuesday",
        },
      ],
      e1: 1,
    };
  },
  computed: {
    templateErrors() {
      const errors = [];
      if (!this.$v.template.$dirty) return errors;
      !this.$v.template.required && errors.push("Item is required");
      return errors;
    },
    scheduleErrors() {
      const errors = [];
      if (!this.$v.schedule.$dirty) return errors;
      !this.$v.schedule.required && errors.push("Item is required");
      return errors;
    },
    benchErrors() {
      const errors = [];
      if (!this.$v.bench.$dirty) return errors;
      !this.$v.bench.between &&
        errors.push("Can't be more than 1080lbs. Current WR.");
      !this.$v.bench.required && errors.push("Item is required");
      return errors;
    },
    squatErrors() {
      const errors = [];
      if (!this.$v.squat.$dirty) return errors;
      !this.$v.squat.between &&
        errors.push("Can't be more than 1105lbs. Current WR.");
      !this.$v.squat.required && errors.push("Item is required");
      return errors;
    },
    deadliftErrors() {
      const errors = [];
      if (!this.$v.deadlift.$dirty) return errors;
      !this.$v.deadlift.between &&
        errors.push("Can't be more than 1104lbs. Current WR.");
      !this.$v.deadlift.required && errors.push("Item is required");
      return errors;
    },
    militaryErrors() {
      const errors = [];
      if (!this.$v.military.$dirty) return errors;
      !this.$v.military.between &&
        errors.push("Can't be more than 500lbs. Current WR.");
      !this.$v.military.required && errors.push("Item is required");
      return errors;
    },
  },
  methods: {
    submitStep1() {
      this.$v.bench.$touch();
      this.$v.squat.$touch();
      this.$v.deadlift.$touch();
      this.$v.military.$touch();
      if (
        !this.$v.bench.$invalid &&
        !this.$v.squat.$invalid &&
        !this.$v.deadlift.$invalid &&
        !this.$v.military.$invalid
      )
        this.e1 += 1;
    },
    submitStep2() {
      this.$v.template.$touch();
      if (!this.$v.template.$invalid) this.e1 += 1;
    },
    async submitNewUserInfo() {
      const data = {
        maxes: {
          bench: this.bench,
          squat: this.squat,
          deadlift: this.deadlift,
          military: this.military,
        },
        schedule: this.schedule,
        template: this.template,
      };

      this.dialog = false;
      await this.$store.dispatch("updateUserProfile", data);
      await this.$store.dispatch("generateUserCycle");
      this.$router.replace({ name: "Cycle" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
