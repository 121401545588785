import Vue from "vue";
import Vuex from "vuex";
import "./types";
import firebase from "./modules/firebase";

Vue.use(Vuex);

interface StateInterface {
  isUserLoaded: boolean;
  loadingUserData: boolean;
  userProfile: UserProfile;
}

export default new Vuex.Store({
  state: {
    isUserLoaded: false,
    loadingUserData: true,
    userProfile: {},
  } as StateInterface,
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = { ...val };
    },
    setUserCycle(state, val) {
      state.userProfile.cycle = val;
    },
    setIsUserLoaded(state, val) {
      state.isUserLoaded = val;
    },
    setLoadingUserData(state, val) {
      state.loadingUserData = val;
    },
  },
  getters: {
    userProfile(state): UserProfile {
      return state.userProfile;
    },
    getOldCycles(state) {
      return state.userProfile.old_cycles || {};
    },
  },
  modules: {
    firebase,
  },
});
