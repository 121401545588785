<template>
  <v-container>
    <v-row justify="center" class="mt-3">
      <v-col cols="10">
        <v-row dense>
          <v-col cols="6">
            <v-card tile link to="/stats/bench">
              <v-card-title>
                Bench
              </v-card-title>
              <v-card-text> {{ maxes.bench }} lbs </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card link to="/stats/military" tile>
              <v-card-title>
                Military
              </v-card-title>
              <v-card-text> {{ maxes.military }} lbs </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-card link to="/stats/squat" tile>
              <v-card-title>
                Squat
              </v-card-title>
              <v-card-text> {{ maxes.squat }} lbs </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card link to="/stats/deadlift" tile>
              <v-card-title>
                Deadlift
              </v-card-title>
              <v-card-text> {{ maxes.deadlift }} lbs </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-card tile flat style="background-color: transparent">
              <v-card-title>Template</v-card-title>
              <v-card-text>
                {{ template | toReadableTemplate }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card tile flat style="background-color: transparent">
              <v-card-title>Schedule</v-card-title>
              <v-card-text>
                {{ scheduleDays }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-dialog v-model="deleteDialog" max-width="350">
        <template #activator="{on, attrs}">
          <v-col cols="10">
            <v-btn v-bind="attrs" v-on="on" text color="error"
              >Delete Account</v-btn
            >
          </v-col>
        </template>

        <v-card tile>
          <v-card-title>Are you sure?</v-card-title>
          <v-card-text>All previously saved data will be gone.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="deleteDialog = false" text>Cancel</v-btn>
            <v-btn @click="deleteAccount" text color="error">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Account",
  data() {
    return {
      deleteDialog: false,
      template: this.$store.state.userProfile.template,
      schedule: this.$store.state.userProfile.schedule,
      maxes: this.$store.state.userProfile.maxes,
    };
  },
  methods: {
    deleteAccount() {
      this.$store.dispatch("deleteAccount");
    },
  },
  computed: {
    scheduleDays() {
      const mapSchedules = {
        monday: "Mon, Wed, Fri",
        tuesday: "Tue, Thu, Sat",
      };

      return mapSchedules[this.schedule];
    },
    isUserLoaded() {
      return this.$store.state.userProfile.loggedIn;
    },
  },
  watch: {
    "$store.state.userProfile.loggedIn": function() {
      this.template = this.$store.state.userProfile.template;
      this.schedule = this.$store.state.userProfile.schedule;
      this.maxes = this.$store.state.userProfile.maxes;
    },
  },
  filters: {
    toReadableTemplate(template) {
      return template.split("_").join(" ");
    },
  },
};
</script>

<style lang="scss" scoped></style>
