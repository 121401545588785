import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyC6Yb45MOfBhXKd2Koq6sX_ubKgpQjTP5s",
  authDomain: "531.elysaakian.com",
  databaseURL: "https://project-7718883110128098715.firebaseio.com",
  projectId: "project-7718883110128098715",
  storageBucket: "project-7718883110128098715.appspot.com",
  messagingSenderId: "878186608418",
  appId: "1:878186608418:web:cd7d2c84dfb6e287d8577b",
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.database();
const auth = firebase.auth();

// export utils/refs
export { db, auth };
