<template>
  <v-stepper flat tile v-model="step" style="height: 100%">
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1">
        Warm up
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="step > 2" step="2">
        Main
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step step="3">
        Accessory
        <small>Recommended</small>
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row
          ><v-spacer></v-spacer
          ><v-col cols="auto">
            <v-btn
              color="warning"
              @click="
                warmupSetsCompleted ? (step = 2) : (completeSetsDialog = true)
              "
              tile
              text
              >Next<v-icon>{{ mdiChevronRight }}</v-icon></v-btn
            ></v-col
          ></v-row
        >
        <DayCard
          :sets="day.warmupSets"
          @set-state-change="value => updateSetState(value, true)"
        >
          <template #title
            ><span class="font-weight-medium">{{
              "Warm-up sets" | toCaps
            }}</span></template
          >
        </DayCard>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-row
          ><v-col cols="auto"
            ><v-btn text tile @click="step = 1"
              ><v-icon>{{ mdiChevronLeft }}</v-icon
              >Back</v-btn
            ></v-col
          ><v-spacer></v-spacer
          ><v-col cols="auto">
            <v-btn
              color="warning"
              text
              tile
              @click="
                isLogged && mainSetsCompleted
                  ? (step = 3)
                  : mainSetsCompleted
                  ? (logRepsDialog = true)
                  : (completeSetsDialog = true)
              "
              >Next<v-icon>{{ mdiChevronRight }}</v-icon>
            </v-btn></v-col
          ></v-row
        >
        <DayCard
          :phase="day.phase"
          :lift="day.lift"
          :sets="day.sets"
          :completed="day.isCompleted"
          @edit-reps="editReps"
          @set-state-change="value => updateSetState(value, false)"
        >
          <template #title
            ><span class="font-weight-medium">{{
              "Main sets" | toCaps
            }}</span></template
          >
        </DayCard>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-row
          ><v-col cols="auto"
            ><v-btn text tile @click="step = 2"
              ><v-icon>{{ mdiChevronLeft }}</v-icon
              >Back</v-btn
            ></v-col
          ></v-row
        >

        <DayCardPlain>
          <template #title
            ><span class="font-weight-medium">{{
              "Accessory exercises" | toCaps
            }}</span></template
          >
          <template #content>
            <div v-for="exercise in assistance" :key="exercise.name">
              <v-row justify="space-between" align="center">
                <v-col cols="auto">
                  <p class="text-h6 font-weight-light">
                    {{ exercise.name | noUnderscore | toCaps }}
                  </p>
                </v-col>
                <v-col cols="auto">
                  <p class="text-h4 font-weight-bold">
                    {{ exercise.sets }}
                    <span class="text-body-1 font-weight-light ">X</span>
                    {{ exercise.reps }}
                    <span class="text-body-1 font-weight-light ">reps</span>
                  </p>
                </v-col>
              </v-row>
            </div>
          </template>
        </DayCardPlain>
      </v-stepper-content>
    </v-stepper-items>
    <v-dialog v-model="logRepsDialog" max-width="350">
      <v-card tile>
        <v-card-title>Log reps from last set</v-card-title>
        <v-card-text
          ><v-text-field
            @input="$v.reps.$touch()"
            @blur="$v.reps.$touch()"
            v-model="reps"
            type="number"
            :error-messages="repsErrors"
            placeholder="Enter reps"
          ></v-text-field
        ></v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn text color="success" @click="saveReps"
            >Save</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="completeSetsDialog" max-width="350">
      <v-card tile flat
        ><v-card-title></v-card-title
        ><v-card-text>Complete all sets first.</v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn text color="warning" @click="completeSetsDialog = false"
            >OK</v-btn
          ></v-card-actions
        ></v-card
      >
    </v-dialog>
  </v-stepper>
</template>

<script>
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DayCard from "../components/DayCard";
import DayCardPlain from "../components/DayCardPlain";
import { toTitleCase } from "../utils/stringOperations";

export default {
  mixins: [validationMixin],
  validations: {
    reps: { required },
  },
  components: {
    DayCard,
    DayCardPlain,
  },
  data() {
    return {
      completeSetsDialog: false,
      reps: "",
      logRepsDialog: false,
      step: 1,
      items: ["Warm up", "Main", "Accessory"],
      mdiChevronRight,
      mdiChevronLeft,
    };
  },
  name: "DayViewStepper",
  computed: {
    isLogged() {
      return (
        this.$store.state.userProfile.cycle.reps[this.day.lift][this.day.phase]
          .reps != ""
      );
    },
    repsErrors() {
      const errors = [];
      if (!this.$v.reps.$dirty) return errors;
      !this.$v.reps.required && errors.push("Enter last set reps");
      return errors;
    },
    warmupSetsCompleted() {
      return Object.values(this.day.warmupSets).every(
        ({ completed }) => completed
      );
    },
    mainSetsCompleted() {
      return Object.values(this.day.sets).every(({ completed }) => completed);
    },
    assistance() {
      return this.$store.state.userProfile.cycle.assistance[this.day.lift];
    },
    week() {
      return this.$route.params.week;
    },
    day() {
      const weekId = this.$route.params.week;
      const dayId = this.$route.params.day;

      const dayObject = this.$store.state.userProfile.cycle.weeks[
        `week${weekId}`
      ][`${dayId - 1}`];

      return dayObject;
    },
    repsInput() {
      return this.day.phase === "deload"
        ? "5"
        : this.$store.state.userProfile.cycle.reps[this.day.lift][
            this.day.phase
          ].reps;
    },
  },
  methods: {
    toTitleCase(str) {
      return (
        str &&
        str.replace(/\w\S*/g, function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
      );
    },
    updateSetState({ newSetState, setIndex }, isWarmUpSet) {
      const updates = {};
      const week = this.$route.params.week;
      const day = this.$route.params.day - 1;
      const setPath = `cycle/weeks/week${week}/${day}/${
        isWarmUpSet ? "warmupSets" : "sets"
      }/${setIndex}/completed`;
      updates[setPath] = newSetState;
      this.$store.dispatch("updateUserProfile", updates);
    },
    editReps(reps) {
      const phase = this.day.phase;
      const lift = this.day.lift;

      const updates = {};
      const repsPath = lift + "/" + phase + "/reps";
      updates[repsPath] = reps;

      this.$store.dispatch("updateReps", updates);
    },
    saveReps() {
      if (this.day.phase == "deload") {
        this.updateDayStatus();
        return;
      }
      if (this.reps == "") {
        this.$v.reps.$touch();
        return;
      }

      const phase = this.day.phase;
      const lift = this.day.lift;
      const weight = this.day.sets[2].weight;

      const updates = {};
      const repsPath = lift + "/" + phase + "/reps";
      const weightPath = lift + "/" + phase + "/weight";
      const timestamp = lift + "/" + phase + "/timestamp";
      updates[repsPath] = this.reps;
      updates[weightPath] = weight;
      updates[timestamp] = Date.now();

      this.logRepsDialog = false;
      this.step = 3;

      this.$store.dispatch("updateReps", updates);
      this.updateDayStatus();
    },

    updateDayStatus() {
      const week = this.$route.params.week;
      const day = this.$route.params.day;

      const weekCompleted =
        this.$store.state.userProfile.cycle.weeks[`week${week}`]["0"]
          .isCompleted &&
        this.$store.state.userProfile.cycle.weeks[`week${week}`]["1"]
          .isCompleted;
      if (weekCompleted) {
        this.$store.dispatch("markWeekCompleted", week);
      }

      const currentDayCompleted = `week${week}/${day - 1}/isCompleted`;
      const currentDayActive = `week${week}/${day - 1}/isActive`;
      const totalDays = 3;
      const nextDay = day < totalDays ? day : undefined;
      const nextDayActive = nextDay
        ? `week${week}/${nextDay}/isActive`
        : undefined;

      const updates = {};

      updates[currentDayCompleted] = true;
      updates[currentDayActive] = false;
      updates[nextDayActive] = nextDayActive ? true : null;

      this.$store.dispatch("updateProgress", updates);
    },
  },
  filters: {
    noUnderscore(text) {
      return text.split("_").join(" ");
    },
    toCaps(str) {
      return str.toUpperCase();
    },
    toTitleCase(str) {
      return toTitleCase(str);
    },
  },
};
</script>

<style lang="scss">
.v-window__container,
.v-window-item,
.v-window-item--active {
  outline: none;
  background-color: #121212;
}
</style>
