<template>
  <v-container fill-height class="align-stretch">
    <v-row justify="center">
      <v-col cols="10">
        <v-row>
          <v-col cols="12">
            <v-select
              flat
              hide-details
              label="Select cycle"
              @change="getData"
              v-model="selectedPeriod"
              :items="['Current cycle', ...formattedOldCyclesKeys]"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              class="my-3"
              flat
              hide-details
              :disabled="this.selectedPeriod.length == 0"
              @change="getData"
              v-model="selectedLift"
              label="Select a lift"
              :items="['bench', 'squat', 'deadlift', 'military']"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-dialog v-model="helpDialog" max-width="350"
              ><template #activator="{on, attrs}"
                ><v-btn icon text v-on="on" v-bind="attrs"
                  ><v-icon>{{ mdiInformation }}</v-icon></v-btn
                ></template
              ><v-card>
                <v-card-title>Help</v-card-title>
                <v-card-text
                  >See how your estimated 1 rep max changes week to week
                  comparing to actual 1 rep max you started the cycle
                  at.</v-card-text
                ></v-card
              ></v-dialog
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <apexchart
          v-show="selectedLift"
          width="100%"
          height="100%"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mdiInformation } from "@mdi/js";

export default {
  name: "StatsView",
  data() {
    return {
      mdiInformation,
      helpDialog: false,
      options: {
        noData: {
          text: "No data here yet.",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: window.matchMedia("(prefers-color-scheme: dark)").matches
              ? "#ffffff"
              : "#212121",
            fontSize: "18px",
            fontFamily: "Roboto",
          },
        },
        title: {
          text: null,
        },
        plotOptions: {
          bar: {
            columnWidth: "75%",
            colors: {
              ranges: [
                {
                  from: 0,
                  to: +Infinity,
                  color: "#4CAF50",
                },
                {
                  from: -Infinity,
                  to: 0,
                  color: "#FF5252",
                },
              ],
            },
          },
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          formatter: value =>
            value > 0
              ? "up " + value + " lbs"
              : "down " + Math.abs(value) + " lbs",
          enabled: true,
        },
        annotations: {
          yaxis: [],
        },
        tooltip: {
          theme: "dark",
          items: {
            display: "flex",
          },
          x: {
            show: false,
          },
          fillSeriesColor: false,
          enabled: true,
          y: {
            formatter: (value, { dataPointIndex }) =>
              this.selectedLiftEstimates[dataPointIndex] + " lbs",

            title: {
              formatter: () => "estimated 1RM:",
            },
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            rotate: 0,
            hideOverlappingLabels: false,
            trim: true,
            style: {
              colors: "#212121",
            },
          },
        },
        yaxis: {
          show: false,
          min: min => min - 10,
          max: max => max + 15,
          tickAmount: 4,
          floating: false,

          labels: {
            style: {
              colors: "#212121",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      },
      selectedLift: "",
      selectedLiftEstimates: [],
      selectedPeriod: "",
      series: [],
    };
  },
  beforeMount() {
    if (this.$route.params.lift) {
      this.selectedLift = this.$route.params.lift;
      this.selectedPeriod = "Current cycle";
      this.getData();
    }
  },
  computed: {
    oldCycles() {
      return this.$store.getters.getOldCycles;
    },
    formattedOldCyclesKeys() {
      return Object.keys(this.oldCycles).map(el => {
        return {
          text:
            el.replace("cycle", "Cycle ") +
            ` - ${moment(this.oldCycles[el].completedOn).fromNow()}`,
          value: el,
        };
      });
    },
    isDark() {
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    },
  },
  methods: {
    getData() {
      this.selectedLiftEstimates = [];
      this.series = [];
      this.options.annotations.yaxis.length = 0;

      const isDark = this.isDark;

      const annotation = {
        y: 0,
        y2: null,
        strokeDashArray: 5,
        borderColor: isDark ? "white" : "#212121",
        opacity: 1,
        offsetX: 0,
        offsetY: 0,
        width: "100%",
        yAxisIndex: 0,
        label: {
          borderWidth: 0,
          borderRadius: 0,
          textAnchor: "start",
          position: "left",
          offsetX: 5,
          offsetY: 15,
          style: {
            background: isDark ? "white" : "#212121",
            color: isDark ? "#212121" : "white",
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "apexcharts-yaxis-annotation-label",
            padding: {
              left: 5,
              right: 5,
              top: 1,
              bottom: 1,
            },
          },
        },
      };

      const lift = this.selectedLift;
      const period = this.selectedPeriod;

      let liftReps;
      if (period != "Current cycle") {
        liftReps = this.oldCycles[period][lift];
      } else {
        liftReps = this.$store.state.userProfile.cycle.reps[lift];
      }
      const liftMax = this.$store.state.userProfile.maxes[lift];

      this.options = {
        ...this.options,
        title: {
          text: `${this.selectedLift} 1RM - ${liftMax}lbs`,
          align: "center",
          style: {
            fontSize: "20px",
            fontWeight: "regular",
            fontFamily: "Roboto",
            color: "#212121",
          },
        },
      };

      const liftData = {
        name: lift,
        data: [],
      };
      for (const phase in liftReps) {
        const { weight, reps } = liftReps[phase];

        if (reps != "") {
          const estimated = Math.round(+weight * +reps * 0.0333 + +weight);
          const difference = estimated - liftMax;
          liftData.data.push(difference);
          this.selectedLiftEstimates.push(estimated);
        }
      }

      if (liftData.data.length == 0) {
        this.options.xaxis.labels.show = false;
        return;
      }

      this.options.xaxis.labels.show = true;
      this.options.annotations.yaxis.push(annotation);
      switch (liftData.data.length) {
        case 1:
          this.options.xaxis.categories = [
            ["Week 1", `${moment(liftReps["phase1"].timestamp).fromNow()}`],
          ];
          break;
        case 2:
          this.options.xaxis.categories = [
            ["Week 1", `${moment(liftReps["phase1"].timestamp).fromNow()}`],
            ["Week 2", `${moment(liftReps["phase2"].timestamp).fromNow()}`],
          ];
          break;
        case 3:
          this.options.xaxis.categories = [
            ["Week 1", `${moment(liftReps["phase1"].timestamp).fromNow()}`],
            ["Week 2", `${moment(liftReps["phase2"].timestamp).fromNow()}`],
            ["Week 3", `${moment(liftReps["phase3"].timestamp).fromNow()}`],
          ];
          break;
        default:
          this.options.xaxis.categories = [];
      }
      this.series.push(liftData);
    },
  },
};
</script>

<style>
.apexcharts-title-text {
  text-transform: capitalize;
}

.apexcharts-menu-item {
  color: #212121 !important;
}

@media (prefers-color-scheme: dark) {
  .apexcharts-tooltip {
    background: white !important;
    color: #212121 !important;
  }

  .apexcharts-title-text,
  .apexcharts-xaxis-label {
    fill: white !important;
  }
}

.apexcharts-toolbar {
  z-index: 8;
}
</style>
