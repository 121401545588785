import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { auth } from "../firebase";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    redirect: "/cycle",
  },
  {
    path: "/app",
    name: "App",
    components: {
      app: () =>
        import(/* webpackChunkName: "AppView" */ "../views/AppView.vue"),
    },
    children: [
      {
        path: "/cycle",
        name: "Cycle",
        component: () =>
          import(/* webpackChunkName: "CycleView" */ "../views/CycleView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/account",
        name: "Account",
        component: () =>
          import(/* webpackChunkName: "Account" */ "../views/Account.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/stats/:lift?",
        name: "Stats",
        component: () =>
          import(/* webpackChunkName: "StatsView" */ "../views/StatsView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/week/:week",
        name: "WeekView",
        component: () =>
          import(/* webpackChunkName: "WeekView" */ "../views/WeekView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/week/:week/day/:day",
        name: "DayView",
        component: () =>
          import(
            /* webpackChunkName: "DayView" */ "../views/DayViewStepper.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    components: {
      signIn: () =>
        import(
          /* webpackChunkName: "Onboarding" */ "../views/OnboardingView.vue"
        ),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/newuser",
    name: "NewUser",
    components: {
      signIn: () =>
        import(/* webpackChunkName: "NewUser" */ "../views/NewUser.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    components: {
      signIn: () =>
        import(/* webpackChunkName: "NewUser" */ "../views/Login.vue"),
    },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    router.push("/login");
  } else {
    next();
  }
});

export default router;
