<template>
  <div>
    <span class="text-h5  font-weight-bold">{{ quantity }}</span
    ><span class="font-weight-light align-self-end">x</span>
    <span class="text-h5  font-weight-bold">{{ plate }}</span
    ><span class="font-weight-light align-self-end">lbs</span>
  </div>
</template>

<script>
export default {
  name: "WeightHelperRow",
  props: ["plate", "quantity"],
};
</script>

<style lang="scss" scoped></style>
