import "../types";
import generateCycle from "../generateCycle";
import * as fb from "../../firebase";
import {
  userCycleWeeksRef,
  repsRef,
  oldCyclesRef,
  userCycleRef,
  userTemplateRef,
  userRef,
  templateRef,
} from "../firebaseRefs";
import router from "../../router";

export default {
  actions: {
    // fetching userProfile once signed in
    async fetchUserProfile({ commit }, user: firebase.User) {
      commit("setLoadingUserData", true);

      const { uid, displayName, email, photoURL } = user;
      // updating basic info on each sign in
      await userRef(uid).update({
        displayName: displayName,
        email,
        profilePicture: photoURL,
      });

      userRef(uid).on("value", snapshot => {
        const userProfile = snapshot.val();
        commit("setUserProfile", { ...userProfile, uid });
      });

      await userCycleRef(uid).once("value", snapshot => {
        if (!snapshot.exists()) {
          router.push("/onboarding");
        } else {
          commit("setIsUserLoaded", true);
          commit("setLoadingUserData", false);
        }
      });
    },

    //update userProfile local and remote
    async updateUserProfile({ rootState }, updates) {
      userRef(rootState.userProfile.uid).update(updates);
    },

    // generate cycle set in state and update userProfile firebase
    async generateUserCycle(
      { commit, rootState },
      user: firebase.User = fb.auth.currentUser
    ) {
      userTemplateRef(user.uid).once("value", async snapshot => {
        const template = snapshot.val();
        if (template) {
          let cycle;
          await templateRef(template)
            .once("value")
            .then(snapshot => {
              cycle = generateCycle(snapshot.val(), rootState.userProfile);
              commit("setUserCycle", cycle);
              commit("setIsUserLoaded", true);
            });
          await userRef(user.uid).update({
            cycle,
          });
        }
      });
    },

    async markWeekCompleted({ rootState }, week) {
      const currentWeekCompleted = `week${week}/isCompleted`;
      const currentWeekActive = `week${week}/isActive`;
      const totalWeeks = Object.keys(rootState.userProfile.cycle.weeks).length;
      const nextWeekIndex = +week < totalWeeks ? +week + 1 : undefined;
      const nextWeekActive = nextWeekIndex
        ? `week${nextWeekIndex}/isActive`
        : undefined;
      const nextWeekDayActive = `week${nextWeekIndex}/0/isActive`;

      const updates = {};
      updates[currentWeekCompleted] = true;
      updates[currentWeekActive] = false;
      updates[nextWeekActive] = nextWeekActive ? true : null;
      updates[nextWeekDayActive] = true;

      await userCycleWeeksRef(rootState.userProfile.uid).update(updates);
    },

    async updateReps({ rootState }, updates) {
      await repsRef(rootState.userProfile.uid).update(updates);
    },

    async updateProgress({ rootState }, updates) {
      await userCycleWeeksRef(rootState.userProfile.uid).update(updates);
    },

    async saveCycle({ rootState }, updates) {
      await oldCyclesRef(rootState.userProfile.uid).update(updates);
    },

    async markCurrentCycleCompleted({ rootState }, updates) {
      await userCycleRef(rootState.userProfile.uid).update(updates);
    },

    async logOut({ rootState, commit }) {
      userRef(rootState.userProfile.uid).off();
      await fb.auth.signOut();
      commit("setIsUserLoaded", false);
      commit("setUserProfile", {});
      router.replace({ name: "Login" });
    },

    async deleteAccount({ rootState, commit }) {
      userRef(rootState.userProfile.uid).off();
      await userRef(rootState.userProfile.uid).remove();
      await fb.auth.signOut();
      commit("setIsUserLoaded", false);
      commit("setUserProfile", {});
      router.replace({ name: "Login" });
    },
  },
};
