<template>
  <v-dialog v-model="dialog" @click:outside="closeDialog">
    <v-progress-linear
      :value="linearProgress"
      background-opacity="0"
    ></v-progress-linear>
    <v-card tile min-height="450" class="onboarding-container-card">
      <v-window v-model="onboarding">
        <v-window-item>
          <v-card flat>
            <v-card-title class="justify-center">Welcome to 5/3/1</v-card-title>
            <v-card-text>
              <p>
                Jim Wendler’s 5/3/1 is all about starting with very light
                weights while progressing slowly and consistently.
              </p>
              <p>
                This extremely popular strength training program is based off of
                the rep schemes 5, 3, 1, as the name suggests.
              </p>
              <p>
                Throughout the routine you will work with percentages based off
                of your max, and strive to hit rep PR’s each workout.
              </p>
            </v-card-text>
          </v-card>
        </v-window-item>
        <v-window-item>
          <v-card flat>
            <v-card-title class="justify-center">Enter 1 RMs</v-card-title>
            <v-card-text
              ><p>5/3/1 is based on your current 1 rep maxes for:</p>
              <ul>
                <li>Bench,</li>
                <li>Military press,</li>
                <li>Deadlift, and</li>
                <li>Squat</li>
              </ul>
              <br />
              <p>
                In order to generate your training plan you will need to know
                and enter those during first cycle set-up.
              </p></v-card-text
            >
          </v-card>
        </v-window-item>
        <v-window-item>
          <v-card flat>
            <v-card-title class="justify-center">All out!</v-card-title>
            <v-card-text
              ><p>
                For each generated training day you will have both warmup sets
                and main sets defined.
              </p>
              <p>
                Check off each set as you go. Use weight helper to figure out
                what plates to put on each side of the barbell.
              </p>
              <p>
                Once you get to a set marked with a “+” that’s your go time. Go
                all out and record the amount of reps you did!
              </p></v-card-text
            >
          </v-card>
        </v-window-item>
        <v-window-item>
          <v-card flat>
            <v-card-title class="justify-center"
              >Accessory exercises</v-card-title
            >
            <v-card-text
              ><p>
                Each lift has pre-defined optional accessory exercises that you
                could do after completing the main set.
              </p>
              <p>
                Those are optional and you can replace them with the ones that
                you prefer. But be smart about what accessory work you do and
                make sure it’s aligned with your overall lifting goal.
              </p></v-card-text
            >
          </v-card>
        </v-window-item>
        <v-window-item>
          <v-card flat>
            <v-card-title class="justify-center"
              >Track you progress</v-card-title
            >
            <v-card-text
              ><p>
                On the STATS screen you will be able to track your current
                progress with the training program.
              </p>
              <p>
                It uses reps you recorded from the previous step to calculate
                your estimated 1 rep max.
              </p>
              <p>
                See how it changes after every workout for a lift. It should be
                in green and always increasing!
              </p></v-card-text
            >
          </v-card>
        </v-window-item>
      </v-window>
      <v-card-actions class="justify-center mb-5"
        ><v-btn v-show="onboarding != 0" text @click="back"
          ><v-icon>{{ mdiChevronLeft }}</v-icon></v-btn
        ><v-btn text color="primary" @click="next"
          >{{
            onboarding == 0
              ? "teach me"
              : onboarding == stepsCount && isShown == undefined
              ? "start"
              : isShown != undefined && onboarding == stepsCount
              ? "close"
              : ""
          }}
          <v-icon v-show="onboarding != stepsCount">{{
            mdiChevronRight
          }}</v-icon></v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";

export default {
  name: "OnboardingDialog",
  props: ["isShown"],
  data() {
    return {
      mdiChevronRight,
      mdiChevronLeft,
      stepsCount: 4,
      onboarding: 0,

      linearProgress: 0,
    };
  },
  computed: {
    dialog() {
      return this.isShown != undefined ? this.isShown : true;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
      this.onboarding = 0;
      this.linearProgress = 0;
    },
    next() {
      if (this.onboarding == this.stepsCount && this.linearProgress == 100) {
        if (this.isShown != undefined) {
          this.$emit("close-dialog");
          this.onboarding = 0;
          this.linearProgress = 0;
          return;
        } else {
          this.$router.push("/newuser");
          return;
        }
      }

      this.onboarding =
        this.onboarding == this.stepsCount
          ? this.stepsCount
          : this.onboarding + 1;
      this.linearProgress =
        this.linearProgress == 100
          ? this.linearProgress
          : this.linearProgress + 25;
    },
    back() {
      this.onboarding = this.onboarding == 0 ? 0 : this.onboarding - 1;
      this.linearProgress =
        this.linearProgress == 0 ? 0 : this.linearProgress - 25;
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-container-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
