<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="auto">
        <p class="text-subtitle-1">
          <slot name="title">Main sets</slot>
        </p>
      </v-col>
    </v-row>
    <v-row
      ><v-container>
        <slot name="content"></slot>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DayCardPlain",
};
</script>

<style lang="scss" scoped></style>
