<template>
  <v-app-bar app elevation="0">
    <v-app-bar-nav-icon>
      <v-icon @click="$router.go(-1)" v-if="!isTopLevelRoute">{{
        mdiArrowLeft
      }}</v-icon>
      <v-icon @click="$emit('toggle:drawer')" v-else>{{ mdiMenu }}</v-icon>
    </v-app-bar-nav-icon>
    <h1 class="text-h5 font-weight-bold">
      {{ screenTitle }}
    </h1>
    <v-spacer></v-spacer>
    <v-app-bar-nav-icon>
      <v-icon @click="onboardingDialog = !onboardingDialog">{{
        mdiHelp
      }}</v-icon>
    </v-app-bar-nav-icon>
    <OnboardingDialog
      :isShown="onboardingDialog"
      @close-dialog="onboardingDialog = false"
    ></OnboardingDialog>
  </v-app-bar>
</template>

<script>
import { mdiMenu, mdiHelp, mdiArrowLeft } from "@mdi/js";
import OnboardingDialog from "./OnboardingDialog";
import { toTitleCase } from "../utils/stringOperations";
import { mapState } from "vuex";

export default {
  name: "TheAppBar",
  props: ["drawer"],
  components: {
    OnboardingDialog,
  },
  data() {
    return {
      onboardingDialog: false,
      mdiMenu,
      mdiArrowLeft,
      mdiHelp,
    };
  },
  computed: {
    ...mapState(["userProfile"]),
    screenTitle() {
      const week = this.$route.params.week;
      const lastWeekIndex = Object.values(this.userProfile.cycle.weeks).length;
      if (this.$route.path.includes("day")) {
        const day = this.$route.params.day;
        const lift = this.$store.state.userProfile.cycle.weeks[`week${week}`][
          day - 1
        ].lift;
        return `${
          week == lastWeekIndex ? "Deload" : "Week " + week
        } - ${toTitleCase(lift)}`;
      }
      if (this.$route.path.includes("week")) {
        return week == lastWeekIndex
          ? `${toTitleCase(this.$route.path.split("/").join(" "))} Deload`
          : toTitleCase(this.$route.path.split("/").join(" "));
      }

      return this.$route.name;
    },
    isTopLevelRoute() {
      return (
        this.$route.name == "Cycle" ||
        this.$route.name == "Stats" ||
        this.$route.name == "Account" ||
        this.$route.name == "NewUser"
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
