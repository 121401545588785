type Plates = {
  [plate: string]: number;
};

export function getPlates(target, isLowerbody): Plates {
  const bb = 45;
  const plates = [45, 25, 10, 5];
  if (isLowerbody) {
    plates.pop();
  }
  const result = {};

  let oneSide = (target - bb) / 2;

  for (let i = 0; i < plates.length; i++) {
    const t = Math.floor(oneSide / plates[i]);
    if (t > 0) {
      result[plates[i]] = t;
      oneSide = oneSide - t * plates[i];
    }
  }

  return result;
}

export function getPlateRoundedWeight(weight, isLowerbody): number {
  const platesObject = getPlates(weight, isLowerbody);

  const sum = Object.entries(platesObject).reduce((acc, [plate, quantity]) => {
    acc += +plate * quantity;
    return acc;
  }, 0);

  return sum * 2 + 45;
}
