<template>
  <v-row class="mt-5">
    <v-col cols="3"><p class="pt-2 font-weight-light">Last set</p></v-col>
    <v-col cols="7">
      <v-text-field
        type="number"
        solo
        suffix="reps"
        dense
        v-model="reps"
        :disabled="!editReps"
      ></v-text-field>
    </v-col>
    <v-col cols="2"
      ><v-btn v-if="!editReps" icon @click="editReps = true"
        ><v-icon color="warning">{{ mdiPencil }}</v-icon></v-btn
      >
      <v-btn v-else icon @click="$emit('edit-reps', reps) && (editReps = false)"
        ><v-icon color="success">{{ mdiCheck }}</v-icon></v-btn
      ></v-col
    >
  </v-row>
</template>

<script>
import { mdiPencil, mdiCheck } from "@mdi/js";

export default {
  name: "EditRepsField",
  props: ["lift", "phase"],
  data() {
    return {
      reps: this.$store.state.userProfile.cycle.reps[this.lift][this.phase]
        .reps,
      editReps: false,
      mdiPencil,
      mdiCheck,
    };
  },
};
</script>

<style lang="scss" scoped></style>
