<template>
  <v-app><OnboardingDialog></OnboardingDialog></v-app>
</template>

<script>
import OnboardingDialog from "../components/OnboardingDialog";

export default {
  components: {
    OnboardingDialog,
  },
};
</script>

<style lang="scss" scoped></style>
