<template>
  <v-row>
    <v-col cols="12">
      <v-row justify="center" class="mt-2">
        <v-col cols="6">
          <p class="font-weight-light text-center ">
            Current 1 Rep Max
          </p>
          <p class="text-h6 text-center ">
            {{ repsToBeatCurrentMax.currentMax }}lbs
          </p>
        </v-col>
        <v-col cols="6">
          <p class="font-weight-light text-center ">
            Reps to beat it
          </p>
          <p class="text-h6 text-center">
            {{ repsToBeatCurrentMax.reps }} reps
          </p>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row justify="center" class="mb-5">
        <v-col cols="6">
          <v-slider
            @change="estimateMax"
            @input="estimateMax"
            v-model="estimatedReps"
            color="primary"
            min="0"
            max="20"
            thumb-label
          >
          </v-slider>
          <p class="text-center">
            <span
              class="display-1 font-weight-light"
              v-text="estimatedReps"
            ></span>
            <span>{{ estimatedReps == 1 ? "rep" : "reps" }}</span>
          </p>
        </v-col>
        <v-col cols="6" align-self="center">
          <p class="text-center">Estimates to</p>
          <p class="text-center">
            <span
              class="display-1 font-weight-light"
              v-text="estimatedMax"
            ></span>
            <span>lbs</span>
          </p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LastSetEstimator",
  props: ["phase", "lift", "sets"],
  data() {
    return {
      estimatedMax: 0,
      estimatedReps: 0,
    };
  },
  beforeMount() {
    this.estimatedReps += this.repsToBeatCurrentMax.reps;
    this.estimateMax();
  },
  methods: {
    estimateMax() {
      const estimate =
        +this.sets[2].weight * 0.0333 * this.estimatedReps +
        +this.sets[2].weight;
      this.estimatedMax = Math.round(estimate);
      if (!this.estimatedReps) this.estimatedMax = 0;
    },
  },
  computed: {
    repsToBeatCurrentMax() {
      const estimate = ({ weight, reps }) =>
        Math.round(+weight * +reps * 0.0333 + +weight);

      const mapPreviousPhase = {
        phase1: this.$store.state.userProfile.maxes[this.lift],
        phase2: estimate(
          this.$store.state.userProfile.cycle.reps[this.lift]["phase1"]
        ),
        phase3: estimate(
          this.$store.state.userProfile.cycle.reps[this.lift]["phase2"]
        ),
        deload: estimate(
          this.$store.state.userProfile.cycle.reps[this.lift]["phase3"]
        ),
      };

      const currentMax = mapPreviousPhase[this.phase];

      if (this.phase == "deload") {
        return {
          currentMax,
          reps: "deload day, no",
        };
      }

      const reps = Math.ceil(
        (currentMax - +this.sets[2].weight) / +this.sets[2].weight / 0.033
      );

      return {
        currentMax,
        reps,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
