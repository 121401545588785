<template>
  <v-app v-if="isUserLoaded">
    <TheAppBar :drawer="drawer" @toggle:drawer="drawer = !drawer"></TheAppBar>
    <v-navigation-drawer app v-model="drawer"
      ><TheNavDrawer @toggle:drawer="drawer = !drawer"></TheNavDrawer
    ></v-navigation-drawer>

    <v-main app>
      <transition :name="transitionName" mode="out-in"
        ><keep-alive include="CycleView"
          ><router-view></router-view
        ></keep-alive>
      </transition>
    </v-main>

    <TheBottomNav
      @change:transitionName="transitionName = $event"
    ></TheBottomNav>
  </v-app>
</template>

<script>
import TheAppBar from "../components/TheAppBar";
import TheNavDrawer from "../components/TheNavDrawer";
import TheBottomNav from "../components/TheBottomNav";

import { mapState } from "vuex";

export default {
  name: "AppView",
  components: {
    TheAppBar,
    TheNavDrawer,
    TheBottomNav,
  },
  data() {
    return {
      drawer: false,
      transitionName: "",
    };
  },
  computed: {
    ...mapState(["isUserLoaded"]),
  },
};
</script>

<style lang="scss" scoped></style>
