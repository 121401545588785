<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="auto">
        <p class="text-subtitle-1">
          <slot name="title">Main sets</slot>
        </p>
      </v-col>
    </v-row>
    <DayCardRow
      v-for="(set, index) in sets"
      :key="index"
      @toggle-weight-helper="toggleWeightHelper"
      @set-state-change="
        value =>
          $emit('set-state-change', {
            newSetState: value,
            setIndex: index,
          })
      "
      :weight="set.weight"
      :reps="set.reps"
      :completed="set.completed"
    ></DayCardRow>

    <EditRepsField
      v-if="lift && completed"
      :lift="lift"
      :phase="phase"
      @edit-reps="value => $emit('edit-reps', value)"
    ></EditRepsField>

    <div v-else-if="lift">
      <LastSetEstimator
        :sets="sets"
        :phase="phase"
        :lift="lift"
      ></LastSetEstimator>
    </div>

    <v-dialog v-model="helper">
      <WeightHelper
        :weight="weightToPlatesComputed"
        @toggle-weight-helper="toggleWeightHelper"
      ></WeightHelper>
    </v-dialog>
  </v-container>
</template>

<script>
import WeightHelper from "./WeightHelper";
import EditRepsField from "./EditRepsField";
import LastSetEstimator from "./LastSetEstimator";

import DayCardRow from "./DayCardRow";
export default {
  name: "DayCard",
  components: {
    DayCardRow,
    WeightHelper,
    LastSetEstimator,
    EditRepsField,
  },
  props: ["sets", "lift", "phase", "completed"],
  data() {
    return {
      helper: false,
      weightToPlates: 0,
    };
  },
  methods: {
    toggleWeightHelper(weight) {
      this.weightToPlates = weight;
      this.helper = !this.helper;
    },
  },
  computed: {
    weightToPlatesComputed() {
      return this.weightToPlates;
    },
  },
};
</script>

<style lang="scss"></style>
